<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import NotificationService from '@/services/notification';

export default {
  page: {
    title: "Push Notification",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Push Notification",
      notification: {
        title: '',
        message: '',
        to: 'all',
      },
      toOptions: [],
    };
  },
  methods: {
    sendNotification() {
      let loader = this.$loading.show();

      NotificationService.sendNotification(this.notification).then(() => {
        loader.hide();

        this.$toast.open('Mensagem enviada com sucesso');
      }).catch((err) => {

        this.$toast.error(err);
        loader.hide();
      });
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <!-- <h4 class="card-title">Notificação Push</h4>
        <p class="card-title-desc">A mensagem será enviada para todos os dispositivos com o aplicativo instalado</p> -->
        <form>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label >Enviar para:</label>
                <!-- <multiselect
                  v-model="notification.to"
                  :options="toOptions"
                  placeholder=""
                  label="description"
                  track-by="code"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  :close-on-select="true"
                ></multiselect> -->

                <select class="form-control" v-model="notification.to"> 
                  <option value="all">Todos</option>
                  <option value="providers">Prestadores</option>
                  <option value="hirers">Oficinas</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <!-- <b-alert show variant="warning">Importante: A mensagem será encaminhada para TODOS os celulares com o aplicativo instalado</b-alert> -->
              <div class="form-group">
                <label for="title">Título da Mensagem</label>
                <input id="title" name="title" type="text" class="form-control" :maxlength="50" v-model="notification.title"/>
                <div v-if="notification.title" class="text-left  ">
                  <p
                    class="badge position-absolute"
                    :class="{ 'badge-soft-secondary': notification.title.length !== 50,
                    'badge-danger': notification.title.length === 50 }"
                  >{{ notification.title.length }} / 50</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="title">Mensagem</label>
                <textarea id="message" class="form-control" rows="6" :maxlength="150" v-model="notification.message" placeholder="Informe aqui o conteúdo da mensagem"></textarea>
                <div v-if="notification.message" class="text-left  ">
                  <p
                    class="badge position-absolute"
                    :class="{ 'badge-soft-secondary': notification.message.length !== 150,
                    'badge-danger': notification.message.length === 150 }"
                  >{{ notification.message.length }} / 1  50</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2 align-items-center">
            <div class="col-sm-6">
              <div class="text-sm-right">
                <!-- <b-spinner class="m-2" variant="primary" role="status" v-if="loading"></b-spinner> -->
                <button type="button" class="btn btn-primary btn-rounded mb-2" @click="sendNotification" :disabled="!notification.title || !notification.message">
                  <i class="mdi mdi-check mr-1"></i> Enviar Mensagem
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
